/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rekonstrukce banky ve Zlíně"}>
        <Column className="pb--30 pt--30" name={"ifpp3bd4bze"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--36 w--300" content={"Rekonstrukce banky ve Zlíně"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" content={"Jednalo se o rekonstrukci, kde si zadavatel přál zmodernizovat původní vstupní halu a vytvořit pracovní kóje, uvítací prostor a nové zázemí pro zaměstnance. Proběhla kompletní výměna stávajících elektrických rozvodů, veškerého osvětlení se systémem DALI a zapojení nového samostatného rozvaděče."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"yvft8nqfrj"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e257c6e9dfd0424ba8397e80538bb9e3_s=1400x_.jpg 1400w"} position={{"x":"-31%","y":"-46.48825065274151%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/68a25fa2dffc47a2bcb0c32226ccaef3_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/68a25fa2dffc47a2bcb0c32226ccaef3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/68a25fa2dffc47a2bcb0c32226ccaef3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/68a25fa2dffc47a2bcb0c32226ccaef3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/68a25fa2dffc47a2bcb0c32226ccaef3_s=1400x_.jpg 1400w"} position={{"x":"-6.117493472584854%","y":"-4.699738903394259%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/cd176cb2418546f3b1136d3dfcc4a179_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/cd176cb2418546f3b1136d3dfcc4a179_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/cd176cb2418546f3b1136d3dfcc4a179_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/cd176cb2418546f3b1136d3dfcc4a179_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/cd176cb2418546f3b1136d3dfcc4a179_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/cd176cb2418546f3b1136d3dfcc4a179_s=2000x_.jpg 2000w"} position={{"x":"-39.42558746736294%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/e9027ea0a5ff402d80e9793fb92c6c1f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/e9027ea0a5ff402d80e9793fb92c6c1f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e9027ea0a5ff402d80e9793fb92c6c1f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e9027ea0a5ff402d80e9793fb92c6c1f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e9027ea0a5ff402d80e9793fb92c6c1f_s=1400x_.jpg 1400w"} position={{"x":"-7.5718015665796345%","y":"-44.90861618798959%"}}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/11759/2d9cb8c75f504452a0bc35de2f12eb31_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/2d9cb8c75f504452a0bc35de2f12eb31_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/2d9cb8c75f504452a0bc35de2f12eb31_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/2d9cb8c75f504452a0bc35de2f12eb31_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/2d9cb8c75f504452a0bc35de2f12eb31_s=1400x_.jpg 1400w"} position={{"x":"-4.911227154046998%","y":"-43.47780678851174%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/fe47adc9c95c41558feaa58b57203ca4_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/fe47adc9c95c41558feaa58b57203ca4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/fe47adc9c95c41558feaa58b57203ca4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/fe47adc9c95c41558feaa58b57203ca4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/fe47adc9c95c41558feaa58b57203ca4_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/f14682d96abe4ce4a0b8ccf5cd888ae1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/f14682d96abe4ce4a0b8ccf5cd888ae1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/f14682d96abe4ce4a0b8ccf5cd888ae1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/f14682d96abe4ce4a0b8ccf5cd888ae1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/f14682d96abe4ce4a0b8ccf5cd888ae1_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}